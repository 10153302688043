// @flow
import axios from 'axios'
import Interface from './interface'

type PublisherInterfaceType = {
  actionName: string,
  ids: Array<number>
}

class PublisherInterface extends Interface {
  update = ({actionName, ids}: PublisherInterfaceType) => {
    let data = {
      action_name: actionName,
      ids: ids
    }

    axios({
      url: `${this.baseUrlWithId}.json`,
      method: 'patch',
      headers: { 'X-CSRF-Token': this.token },
      data: data
    })
      .then((resp) => {
        window.location.href = this.indexUrl
      })
      .catch((err) => console.warn(err))
  }
}

export default PublisherInterface
