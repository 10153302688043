class Interface {
  baseUrl: string
  indexUrl: string
  token: string

  constructor ({baseUrlWithId, indexUrl}: ConstructorArgs) {
    this.baseUrlWithId = baseUrlWithId
    this.indexUrl = indexUrl
    this.token = this._fetchAuthenToken()
    this.id = this._parseId(baseUrlWithId)
  }

  _parseId = (urlWithId) => urlWithId.split('/').slice(-1)[0]

  _fetchAuthenToken = (): string => {
    const head: HTMLElement = ((document.head: any): HTMLElement)
    const token: string = (head.querySelector("meta[name='csrf-token']"): any).content
    if (typeof token === 'string') {
      return (head.querySelector("meta[name='csrf-token']"): any).content
    } else {
      throw Error('Cannot find authenticity token in document <head>.')
    }
  }
}

export default Interface
