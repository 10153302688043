// @flow
import { q, qAll } from '../src/utils/js_helpers'
import flatpickr from 'flatpickr'
import PublisherInterface from '../src/articles/controller_interfaces/publisher_interface'
import { flatpickrActionPane } from '../src/libs/flatpickr-actions'

let publishState = {
  ids: [],
  actionName: ''
}

let articleSelected: Event = new Event('article_selected')

type ArticlePublisherArgs = {
  actionCheckbox: HTMLInputElement,
  articleId: number,
  bulkActionTriggers: HTMLDivElement
}

class Publisher {
  actionCheckbox: HTMLInputElement
  articleId: number
  bulkActionTriggers: HTMLDivElement

  constructor ({actionCheckbox, articleId, bulkActionTriggers}: ArticlePublisherArgs) {
    this.actionCheckbox = actionCheckbox
    this.articleId = articleId
    this.bulkActionTriggers = bulkActionTriggers
  }

  init = () => {
    this.actionCheckbox.addEventListener('change', evt => {
      if (evt.target instanceof HTMLInputElement) {
        const actionCheckbox: HTMLInputElement = evt.target
        if (actionCheckbox.checked) {
          publishState.ids.push(this.articleId)
          this.bulkActionTriggers.dispatchEvent(articleSelected)
        } else {
          const removeIdx = publishState.ids.indexOf(this.articleId)
          publishState.ids.splice(removeIdx, 1)
          this.bulkActionTriggers.dispatchEvent(articleSelected)
        }
      }
    })
  }
}

const setEventToBulkActionBtn = (btn: HTMLButtonElement): void => {
  btn.addEventListener('click', (evt: Event) => {
    const actionName = ((evt.target: any): HTMLButtonElement).getAttribute('data-action')
    if (actionName) {
      publishState.actionName = actionName
      const dataHolder: HTMLElement = ((q('.bulk-action-triggers'): any): HTMLElement)
      if (dataHolder &&
        dataHolder.getAttribute('data-bulk-url') &&
        dataHolder.getAttribute('data-article-index-url')
      ) {
        const bulkUrl = dataHolder.getAttribute('data-bulk-url')
        const articleIndexUrl = dataHolder.getAttribute('data-article-index-url')
        const publisherInterface = new PublisherInterface({
          baseUrlWithId: bulkUrl,
          indexUrl: articleIndexUrl
        })
        publisherInterface.update({
          actionName: publishState.actionName,
          ids: publishState.ids
        })
      }
    } else {
      throw new Error('Cannot fetch actionName from button. Please set `data-action` on the button.')
    }
  })
}

document.addEventListener('turbolinks:load', () => {
  const articles: HTMLCollection<HTMLDivElement> = ((qAll('.article'): any): HTMLCollection<HTMLDivElement>)
  if (articles.length > 0) {
    const bulkActionTriggers: HTMLDivElement = ((q('.bulk-action-triggers'): any): HTMLDivElement)
    const bulkActionBtns: Array<HTMLButtonElement> = ((Array.from(qAll('.bulk-action')): any): Array<HTMLButtonElement>)
    bulkActionBtns.forEach(btn => setEventToBulkActionBtn(btn))

    // Handle event emit by each check box
    // Show publication btns if one or more checkbox is checked
    bulkActionTriggers.addEventListener('article_selected', (evt: Event) => {
      const { target } = evt
      if (target instanceof HTMLDivElement && publishState.ids.length > 0) {
        const bulkActionContainer = q('.bulk-action-container')
        if (bulkActionContainer instanceof HTMLButtonElement) {
          bulkActionContainer.removeAttribute('disabled')
        }
        Array.from(target.querySelectorAll('.bulk-action-trigger')).forEach((btn: HTMLElement) => btn.removeAttribute('disabled'))
      } else {
        Array.from(((target: any): HTMLDivElement).querySelectorAll('button'))
          .forEach((btn: HTMLElement) => btn.setAttribute('disabled', 'true'))
      }
    })

    // Initialize check boxes as each publisher component
    const publishers: Array<Publisher> = Array.from(articles).map(el => {
      return new Publisher({
        actionCheckbox: ((el.querySelector('.bulk-select'): any): HTMLInputElement),
        articleId: (el.getAttribute('data-article-id') ? parseInt(el.getAttribute('data-article-id'), 10) : -1),
        bulkActionTriggers: bulkActionTriggers
      })
    })
    publishers.forEach((pub: Publisher) => pub.init())
  }
})
